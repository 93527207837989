export const adjective = {
	singular: "vet clinic",
	plural: "vet clinics",
};

export const verb = {
	singular: "treating",
};

export const job = {
	singular: "appointment",
	plural: "appointments",
};
